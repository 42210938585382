import getConfig from 'next/config'

import type { AdyenContext } from '@grandvisionhq/payments'

const { publicRuntimeConfig } = getConfig()

const adyen = publicRuntimeConfig.adyen ?? {}

export const getAdyenConfig = (): AdyenContext => ({
  clientKey: adyen.clientKey,
  environment: adyen.environment,
  merchantName: adyen.merchantName,
  config: {
    creditcard: {
      hasHolderName: true,
      holderNameRequired: true,
      brands: ['mc', 'maestro', 'visa', 'amex'],
    },
    googlepay: {
      allowedAuthMethods: ['CRYPTOGRAM_3DS', 'PAN_ONLY'],
      allowedCardNetworks: ['MASTERCARD', 'VISA'],
      merchantId: adyen.gPayMerchantId,
      environment: adyen.gPayEnvironment,
      merchantName: 'Générale d\'Optique'
    },
  },
})
